import React, { Component } from "react";

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: window.innerWidth > 768, // Assuming desktop if width is greater than 768px
    };
  }

  // Update the state based on the window size
  handleResize = () => {
    this.setState({ isDesktop: window.innerWidth > 768 });
  };

  componentDidMount() {
    // Add event listener for window resize
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Clean up event listener on component unmount
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="form">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3"
                data-animate-effect="fadeInLeft"
              >
                <h2 className="colorlib-heading">Order from Us</h2>
                <h3>
                  This is our regular menu order form for easy ordering. We have
                  fruits, cheese, meats, and veggies suitable for any event and
                  dietary preference.
                </h3>
                <p>
                  To place an order, please submit this form or contact us via
                  Instagram, Facebook, Email, or Phone.
                </p>
                <a
                  itemProp="url"
                  aria-label="Order form link"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfwb86q9fEfk379IvElZxYiep95fJLxfR_DM7e7h_jZWLkGYQ/viewform"
                  target="_blank" // Open in a new tab
                  rel="noopener noreferrer" // Security measure
                  style={{
                    fontWeight: "bold", // Makes the text bold
                    fontSize: "18px", // Increases font size
                    color: "#fff", // White text color
                    backgroundColor: "#df7b86", // Green background color
                    padding: "12px 24px", // Adds padding for a button-like appearance
                    borderRadius: "5px", // Rounds the corners
                    textDecoration: "none", // Removes underline
                    display: "inline-block", // Allows padding and margin to work properly
                    marginTop: "20px", // Adds space above the link
                    transition: "background-color 0.3s", // Adds a transition effect for hover
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#df7b85")
                  } // Darker green on hover
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#df7b86")
                  } // Reset background color on mouse leave
                >
                  Click here to enter your order!
                </a>
              </div>
            </div>
          </div>

          {this.state.isDesktop ? (
            <div className="container">
              <iframe
                className="responsive-iframe"
                src="https://docs.google.com/forms/d/e/1FAIpQLSfwb86q9fEfk379IvElZxYiep95fJLxfR_DM7e7h_jZWLkGYQ/viewform?embedded=true"
                height="1000px"
                marginWidth="0"
                frameBorder="0"
                title="Order Form"
                loading="lazy"
              >
                Loading…
              </iframe>
            </div>
          ) : (
            <p>
              Please visit our order page on a desktop device to fill out the
              form.
            </p>
          )}
        </section>
      </div>
    );
  }
}
