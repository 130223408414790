import React, { Component } from "react";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { isCollapsed: false };
  }

  toggleNavbar = () => {
    this.setState((prevState) => ({ isCollapsed: !prevState.isCollapsed }));
  };

  handleExternalLink = (url) => {
    window.open(url, "_blank"); // Opens in a new tab
  };

  render() {
    const { isCollapsed } = this.state;
    return (
      <div>
        <div>
          <button
            onClick={this.toggleNavbar}
            className="js-colorlib-nav-toggle colorlib-nav-toggle"
            style={{
              backgroundColor: "#df7b86",
              color: "#ffffff",
              border: "2px solid #ffffff",
              padding: "10px 15px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Menu
          </button>
          <aside id="colorlib-aside">
            <div className="text-center">
              <div
                className="author-img"
                style={{ backgroundImage: "url(images/about.png)" }}
              />
              <h2 id="colorlib-logo">
                <a href="/">Austin Charcuterie Shop</a>
              </h2>
            </div>
            <nav
              id="colorlib-main-menu"
              role="navigation"
              className={`navbar ${isCollapsed ? "collapse show" : "collapse"}`}
            >
              <div id="navbar">
                <ul>
                  <li className="active">
                    <a href="#home" data-nav-section="home">
                      Introduction
                    </a>
                  </li>
                  <li>
                    <a href="#gallery" data-nav-section="gallery" rel="nofollow">
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a href="#pricing" data-nav-section="pricing" rel="nofollow">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a href="#form" data-nav-section="form" rel="nofollow">
                      Order Form
                    </a>
                  </li>
                  <li>
                    <a href="#about" data-nav-section="about">
                      About
                    </a>
                  </li>
                  {/* <li>
                    <button
                      onClick={() =>
                        this.handleExternalLink("https://www.facebook.com/austincharcuterieshop")
                      }
                      className="link-button"
                    >
                      Facebook
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        this.handleExternalLink("https://www.instagram.com/austincharcuterie/")
                      }
                      href="https://www.instagram.com/austincharcuterie/"
                      className="link-button"
                    >
                      Instagram
                      <i className="icon-instagram" />
                    </button>
                  </li> */}
                </ul>
              </div>
            </nav>
            <nav id="colorlib-main-menu">
              <ul>
                <li>
                  <p>(337) 214-3347</p>
                </li>
                <span className="email">
                  <i className="icon-mail"></i> austincharcuterie@gmail.com
                </span>
              </ul>
            </nav>
          </aside>
        </div>
      </div>
    );
  }
}
