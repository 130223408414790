import React, { Component } from "react";

export default class Introduction extends Component {
  render() {
    return (
      <div>
        <section
          id="colorlib-hero"
          className="js-fullheight"
          data-section="home"
        >
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li style={{ backgroundImage: "url(images/background.jpg)" }}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner js-fullheight">
                        <div className="desc">
                          <title>Austin Charcuterie Shop</title>
                          <header>
                            <h1>Welcome to Austin Charcuterie Shop!</h1>
                            <h2>
                              We have the best charcuterie boards in Austin!
                            </h2>
                          </header>

                          {/* Social Media Buttons */}
                          <div
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <a
                              href="https://www.facebook.com/austincharcuterieshop"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                backgroundColor: "#3b5998",
                                color: "#fff",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                textDecoration: "none",
                                marginRight: "10px",
                                display: "inline-block",
                              }}
                            >
                              Facebook
                            </a>
                            <a
                              href="https://www.instagram.com/austincharcuterie/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                backgroundColor: "#E4405F",
                                color: "#fff",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                textDecoration: "none",
                                display: "inline-block",
                              }}
                            >
                              Instagram
                            </a>
                          </div>

                          {/* Submit an Order Button */}
                          <div
                            style={{ marginTop: "20px", textAlign: "center" }}
                          >
                            <a
                              href="https://docs.google.com/forms/d/e/1FAIpQLSfwb86q9fEfk379IvElZxYiep95fJLxfR_DM7e7h_jZWLkGYQ/viewform"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                backgroundColor: "#4CAF50", // Change color as needed
                                color: "#fff",
                                padding: "15px 30px", // Wider padding
                                borderRadius: "5px",
                                textDecoration: "none",
                                display: "inline-block",
                                width: "250px", // Set a fixed width for the button
                                marginTop: "10px", // Space above the button
                              }}
                            >
                              Submit an Order!
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}
