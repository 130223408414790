import React, { Component } from "react";

export default class Gallery extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="gallery">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3"
                data-animate-effect="fadeInLeft"
              >
                <h2 className="colorlib-heading">
                  Here are some of my past arrangements
                </h2>
              </div>
            </div>
            <div className="row row-pt-md">
              <div className="col-md-4 text-center">
                <div
                  className="project"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/0160.jpg)`,
                  }}
                  alt="Beautiful charcuterie board with gourmet cheeses and meats local ingredients"
                ></div>
              </div>
              <div className="col-md-4 text-center">
                <div
                  className="project"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/0161.jpg)`,
                  }}
                  alt="Grazing board for parties with fresh fruits, nuts, and cheese"
                ></div>
              </div>
              <div className="col-md-4 text-center">
                <div
                  className="project"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/0162.jpg)`,
                  }}
                  alt="Appetizer platter with cured meats and seasonal fruits from mom-and-pop shop"
                ></div>
              </div>
              <div className="col-md-4 text-center">
                <div
                  className="project"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/0163.jpg)`,
                  }}
                  alt="Charcuterie board featuring artisanal cheeses and nuts from local chef"
                ></div>
              </div>
              <div className="col-md-4 text-center">
                <div
                  className="project"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/0164.jpg)`,
                  }}
                  alt="Large grazing board for events with cheese and veggie options from small business"
                ></div>
              </div>
              <div className="col-md-4 text-center">
                <div
                  className="project"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/0165.jpg)`,
                  }}
                  alt="Customized charcuterie board for special gatherings"
                ></div>
              </div>
              <div className="col-md-4 text-center">
                <div
                  className="project"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/0167.jpg)`,
                  }}
                  alt="Elegant cheese and meat board with gourmet selection and fruits"
                ></div>
              </div>
              <div className="col-md-4 text-center">
                <div
                  className="project"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/0168.jpg)`,
                  }}
                  alt="Party grazing board with assorted fruits, cheeses, and charcuterie and fruits shaped like flowers and roses"
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
